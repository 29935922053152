<template>
  <div v-if="userGroup == 'administrator' || userGroup == 'manage'">
    <CCard>
      <CCardHeader>
        <strong>ຂໍ້ມູນສັນຍາບໍລິການ</strong>
        <CButton @click="modalCreate = true" variant="outline" color="primary" class="float-right">
          <CIcon name="cil-plus" />ສ້າງສັນຍາ
        </CButton>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <CDataTable
              :items="items"
              :fields="fields"
              :loading="loading"
              hover
              sorter
              :table-filter="{placeholder: 'ຄົ້ນຫາ...', label: ' ', align: 'right'}"
              :items-per-page="20"
              pagination
            >
              <template #cont_status="{item}">
                <td>
                  <CBadge :color="getBadge(item.cont_status)">
                    <strong v-if="item.cont_status==0">ລໍາຖ້າຮຽກເກັບ</strong>
                    <strong v-if="item.cont_status==1">ຮຽກເກັບແລ້ວ</strong>
                  </CBadge>
                </td>
              </template>
              <template #appl_status="{item}">
                <td>
                  <CBadge :color="getBadge(item.appl_status)">
                    <strong v-if="item.appl_status==0">ລໍາຖ້າເບີກ</strong>
                    <strong v-if="item.appl_status==1">ເບິກແລ້ວ</strong>
                  </CBadge>
                </td>
              </template>
              <template #actions="row">
                <td>
                  <CDropdown
                    size="sm"
                    toggler-text="ຈັດການ"
                    color="dark"
                    class="m-0 d-inline-block"
                  >
                    <CDropdownItem
                      :disabled="userGroup != 'administrator'"
                      v-on:click="onClickView(row.item)"
                    >ລາຍລະອຽດ</CDropdownItem>
                    <CDropdownItem v-on:click="onClickRenew(row.item)">ຕໍ່ສັນຍາ</CDropdownItem>
                    <CDropdownItem
                      v-on:click="onClickUpdate(row.item)"
                    >ແກ້ໄຂ</CDropdownItem>
                    <CDropdownItem
                      :disabled="row.item.cont_status == 1 || row.item.appl_status == 1 || userGroup != 'administrator' "
                      v-on:click="onClickDelete(row.item)"
                    >ລົບ</CDropdownItem>
                  </CDropdown>
                </td>
              </template>
            </CDataTable>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CModal
      title="ຟອມສ້າງສັນຍາການບໍລິການ"
      size="lg"
      :show.sync="modalCreate"
      :closeOnBackdrop="false"
    >
      <contractFormCreate
        :cus="cusList"
        :service="serviceList"
        @on_success="onSubmitCreate"
        @on_cancle="onSubmitCancle"
      />
      <template #footer>
        <div></div>
      </template>
    </CModal>
    <CModal title="ລາຍລະອຽດຂອງສັນຍາ" size="lg" :show.sync="modalView" :closeOnBackdrop="false">
      <contractView
        :reportCont="reportCont"
        :reportInvList="reportInvList"
        :reportApplList="reportApplList"
      />
      <template #footer>
        <div></div>
      </template>
    </CModal>
    <CModal
      title="ຟອມຕໍ່ສັນຍາການບໍລິການ"
      size="lg"
      :show.sync="modalRenew"
      :closeOnBackdrop="false"
    >
      <contractRenewFormCreate
        :contract="contract"
        :service="serviceList"
        @on_success="onSubmitRenew"
      />
      <template #footer>
        <div></div>
      </template>
    </CModal>
    <CModal title="ຟອມແກ້ໄຂສັນຍາ" size="lg" :show.sync="modalUpdate" :closeOnBackdrop="false">
      <contractFormUpdate :contract="contract" @on_success="onSubmitUpdate" />
      <template #footer>
        <div></div>
      </template>
    </CModal>
  </div>
</template>
<script>
import {
  getContractAPI,
  createContractAPI,
  deleteContractAPI,
  renewContractAPI,
  updateContractAPI,
} from "@/api/contract";
import { getCustomerAPI } from "@/api/customer";
import { getServiceAPI } from "@/api/service";
import { getReportContractByIdAPI } from "@/api/report-contract";
import { mapActions } from "vuex";
import contractFormCreate from "./components/ContractFormCreate";
import contractView from "./components/ContractView";
import contractRenewFormCreate from "./components/ContractRenewFormCreate";
import contractFormUpdate from "./components/ContractFormUpdate";
import { decrypt } from "@/libs/crypto";
export default {
  name: "Contract",
  components: {
    contractFormCreate,
    contractView,
    contractRenewFormCreate,
    contractFormUpdate,
  },
  data() {
    return {
      userGroup: decrypt(this.$store.getters.getUserGroup),
      loading: true,
      modalCreate: false,
      modalView: false,
      modalRenew: false,
      modalUpdate: false,
      items: [],
      fields: [
        { key: "cont_id", label: "ລໍາດັບ" },
        { key: "cont_no", label: "ເລກທີສັນຍາ" },
        { key: "cus_full_name", label: "ຊື່ລູກຄ້າ" },
        { key: "cus_tel", label: "ເບີໂທ" },
        { key: "cont_end_date", label: "ວັນໝົດສັນຍາ" },
        { key: "cus_group", label: "ປະເພດລູກຄ້າ" },
        { key: "cont_status", label: "ສະຖານະ" },
        { key: "appl_status", label: "ອຸປະກອນ" },
        { key: "actions", label: "ຈັດການ" },
      ],
      cusList: [],
      serviceList: [],
      reportCont: {},
      reportInvList: [],
      reportApplList: [],
      contract: {},
    };
  },
  mounted() {
    this.initialGetData();
  },
  methods: {
    ...mapActions("contract", ["contractCreate", "contractClear"]),
    getBadge(status) {
      return status === "0" ? "danger" : status === "1" ? "success" : "success";
    },
    // Load data  document income
    initialGetData() {
      setTimeout(() => {
        this.loading = false;
      }, 5000);
      getContractAPI().then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
      getCustomerAPI().then((response) => {
        this.cusList = response.data.data;
      });
      getServiceAPI().then((response) => {
        this.serviceList = response.data.data;
      });
    },

    onSubmitCreate(credentials) {
      createContractAPI(credentials)
        .then((response) => {
          this.$awn.success(response.data.message);
          this.initialGetData();
        })
        .catch((error) => {
          this.$awn.alert(error.response.data.message);
        });
      this.modalCreate = false;
    },
    onSubmitCancle() {
      this.contractClear().then((response) => {
        this.initialGetData();
      });
      this.modalCreate = false;
    },
    onClickView(item) {
      getReportContractByIdAPI(item.cont_no).then((response) => {
        this.reportCont = response.data.data;
        this.reportInvList = response.data.data.invioce;
        this.reportApplList = response.data.data.appliance;
        this.modalView = true;
      });
    },
    onClickUpdate(item) {
      this.contract = item;
      this.modalUpdate = true;
    },
    onSubmitUpdate(credentials) {
      updateContractAPI(credentials)
        .then((response) => {
          this.$awn.success(response.data.message);
          this.initialGetData();
        })
        .catch((error) => {
          this.$awn.alert(error.response.data.message);
        });
      this.modalUpdate = false;
    },
    onClickRenew(item) {
      this.contract = item;
      this.modalRenew = true;
    },
    onSubmitRenew(credentials) {
      renewContractAPI(credentials)
        .then((response) => {
          this.$awn.success(response.data.message);
          this.initialGetData();
        })
        .catch((error) => {
          this.$awn.alert(error.response.data.message);
        });
      this.modalRenew = false;
    },
    onClickDelete(item) {
      let onOk = () => {
        deleteContractAPI(item.cont_no)
          .then((response) => {
            this.$awn.success(response.data.message);
            this.initialGetData();
          })
          .catch((error) => {
            this.$awn.alert(error.response.data.message);
          });
      };
      this.$awn.confirm("ເຈົ້າຕ້ອງການລົບຂໍ້ມູນນີ້ແທ້ ຫຼີ ບໍ່ ?", onOk);
    },
  },
};
</script>
