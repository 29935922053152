<template>
  <div>
    <CRow>
      <CCol sm="12">
        <div>
          <CRow>
            <CCol sm="12">
              <CRow>
                <CCol sm="1"></CCol>
                <CCol>
                  <CRow>
                    <CCol sm="4" class="text-right">ເລກທີ :</CCol>
                    <CCol sm="6">{{contract.cont_no}}</CCol>
                    <CCol sm="4" class="text-right">ວັນທິ :</CCol>
                    <CCol sm="6">{{contract.cont_date}}</CCol>
                    <CCol sm="4" class="text-right">ລະຫັດລູກຄ້າ :</CCol>
                    <CCol sm="6">{{contract.cus_id}}</CCol>
                    <CCol sm="4" class="text-right">ຊື່ລຸກຄ້າ :</CCol>
                    <CCol sm="6">{{contract.cus_full_name}}</CCol>
                    <CCol sm="4" class="text-right">ທີ່ຢູ່ :</CCol>
                    <CCol sm="6">{{contract.cus_address}}</CCol>
                    <CCol sm="4" class="text-right">ເບີໂທ :</CCol>
                    <CCol sm="6">{{contract.cus_tel}}</CCol>
                    <CCol sm="4" class="text-right">ແພັກເກັດ :</CCol>
                    <CCol sm="6">{{contract.service_name}}</CCol>
                    <CCol sm="4" class="text-right">ລາຄາ :</CCol>
                    <CCol
                      sm="6"
                    >{{contract.service_price | numFormat(',000.00')}} / {{contract.service_unit}}</CCol>
                    <CCol sm="4" class="text-right">ວັນທີໝົດສັນຍາ :</CCol>
                    <CCol sm="6">{{contract.cont_end_date}}</CCol>
                    <CCol sm="12">
                      <div class="my-4"></div>
                    </CCol>
                    <CCol sm="12">
                      <hr />
                    </CCol>
                    <CCol sm="12">
                      <ValidationObserver v-slot="{ invalid }">
                        <CForm ref="form" :model="form" @submit.prevent="handleSubmit">
                          <CRow>
                            <CCol sm="6">
                              <validation-provider rules="required">
                                <div class="form-group">
                                  <label>ວັນໝົດສັນຍາໃໝ່ :</label>
                                  <date-picker
                                    :config="options"
                                    prop="cont_end_date"
                                    v-model="form.cont_end_date"
                                  ></date-picker>
                                </div>
                              </validation-provider>
                            </CCol>
                            <CCol sm="6">
                              <validation-provider rules="required">
                                <div class="form-group">
                                  <label>ເລືອກບໍລິການ :</label>
                                  <v-select
                                    prop="service_id"
                                    v-model="form.service_id"
                                    :options="service"
                                    :reduce="service_id => service_id.service_id"
                                    label="service_name"
                                  />
                                </div>
                              </validation-provider>
                            </CCol>
                            <CCol sm="12">
                              <CButton
                                :disabled="invalid"
                                type="submit"
                                color="success"
                                style="width: 9rem"
                                class="float-right"
                              >
                                <CIcon name="cil-check" />ບັນທຶກຕໍ່ສັນຍາ
                              </CButton>
                            </CCol>
                          </CRow>
                        </CForm>
                      </ValidationObserver>
                    </CCol>
                  </CRow>
                </CCol>
                <CCol sm="1"></CCol>
              </CRow>
            </CCol>
          </CRow>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { decrypt } from "@/libs/crypto";
export default {
  name: "ContractRenewFormCreate",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    contract: {
      type: Object,
      required: true,
    },
    service: {
      type: Array,
      required: true,
    },
  },
  computed: {
    form() {
      return {
        cont_end_date: "",
        service_id: "",
      };
    },
  },
  data(){
    return {
      options: {
        format: "YYYY-MM-DD",
        useCurrent: false,
      },
    }
  },
  methods: {
    handleSubmit() {
      this.$emit("on_success", {
        cont_no: this.contract.cont_no,
        cont_end_date: this.form.cont_end_date,
        service_id: this.form.service_id,
        cont_by: decrypt(this.$store.getters.getFullName),
      });
      this.form.cont_end_date = "";
      this.form.service_id = "";
    },
  },
};
</script>