<template>
  <div>
    <CForm ref="form" :model="form" @submit.prevent="handleSubmit">
      <CRow>
        <CCol sm="1"></CCol>
        <CCol sm="3">ເລກທີ່ສັນຍາບໍລິການ :</CCol>
        <CCol sm="3">{{contract.cont_no}}</CCol>
        <CCol sm="2" class="text-right">ວັນທີ :</CCol>
        <CCol sm="3">{{contract.cont_date}}</CCol>
        <CCol sm="1"></CCol>
        <CCol sm="3">ຊື່ ແລະ ນາມສະກຸນ :</CCol>
        <CCol sm="8">{{contract.cus_full_name}}</CCol>
        <CCol sm="1"></CCol>
        <CCol sm="3">ເບີໂທ :</CCol>
        <CCol sm="8">{{contract.cus_tel}}</CCol>
        <CCol sm="1"></CCol>
        <CCol sm="3">ທີ່ຢູ່ :</CCol>
        <CCol sm="8">{{contract.cus_address}}</CCol>
        <CCol sm="1"></CCol>
        <CCol sm="3">ເລືອກບໍລິການ :</CCol>
        <CCol sm="8">{{contract.service_name}}</CCol>
        <CCol sm="1"></CCol>
        <CCol sm="3">ລາຄາ :</CCol>
        <CCol sm="8">{{contract.service_price | numFormat(',000.00')}} / {{contract.service_unit}}</CCol>
        <CCol sm="1"></CCol>
        <CCol sm="3">ວັນສິນສຸດຂອງສັນຍາ :</CCol>
        <CCol sm="8">{{contract.cont_end_date}}</CCol>
        <CCol sm="1"></CCol>
        <CCol sm="10">
          <validation-provider rules="required">
            <CTextarea
              label="ລາຍລະອຽດຂອງສັນຍາ :"
              prop="con_sv_remark"
              v-model="form.con_sv_remark"
              rows="3"
            />
          </validation-provider>
        </CCol>
        <CCol sm="11">
          <div class="float-right">
            <CButton type="submit" variant="outline" color="warning">
              <CIcon name="cil-save" />ບັນທຶກ
            </CButton>
          </div>
        </CCol>
      </CRow>
    </CForm>
  </div>
</template>
 <script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { decrypt } from "@/libs/crypto";
export default {
  name: "ContractFormUpdate",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    contract: {
      type: Object,
      required: true,
    },
  },
  computed: {
    form() {
      return {
        con_sv_remark: this.contract.con_sv_remark,
      };
    },
  },
  methods: {
    handleSubmit() {
      this.$emit("on_success", {
        cont_id: this.contract.cont_id,
        con_sv_remark: this.form.con_sv_remark,
        cont_by: decrypt(this.$store.getters.getFullName),
      });
    },
  },
};
</script>