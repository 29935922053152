<template>
  <div>
    <CRow>
      <CCol sm="12">
        <div>
          <CCard border-color="success">
            <CCardHeader>ກະລຸນາປ້ອນຂໍ້ມູນ</CCardHeader>
            <CCardBody>
              <ValidationObserver v-slot="{ invalid }">
                <CForm ref="form" :model="form" @submit.prevent="handleAddCus">
                  <CRow>
                    <CCol sm="6">
                      <validation-provider rules="required">
                        <div class="form-group">
                          <label>ລະຫັດລູກຄ້າ :</label>
                          <v-select
                            prop="cus_id"
                            v-model="form.cus_id"
                            :options="cus"
                            :reduce="cus_id => cus_id.cus_id"
                            label="cus_id"
                          />
                        </div>
                      </validation-provider>
                    </CCol>
                    <CCol sm="6">
                      <validation-provider rules="required">
                        <div class="form-group">
                          <label>ບໍລິການ :</label>
                          <v-select
                            prop="service_id"
                            v-model="form.service_id"
                            :options="service"
                            :reduce="service_id => service_id.service_id"
                            label="service_name"
                          />
                        </div>
                      </validation-provider>
                    </CCol>
                    <CCol sm="6">
                      <validation-provider rules="required">
                        <div class="form-group">
                          <label>ວັນສິນສຸດຂອງສັນຍາ :</label>
                          <date-picker
                            :config="options"
                            prop="cont_end_date"
                            v-model="form.cont_end_date"
                          ></date-picker>
                        </div>
                      </validation-provider>
                    </CCol>
                    <CCol sm="12">
                      <validation-provider rules="required">
                        <CTextarea
                          label="ລາຍລະອຽດຂອງສັນຍາ :"
                          prop="con_sv_remark"
                          v-model="form.con_sv_remark"
                          rows="3"
                        />
                      </validation-provider>
                    </CCol>
                    <CCol sm="12">
                      <CButton type="submit" :disabled="invalid" color="info" class="float-right">
                        <CIcon name="cil-check" />ຕົກລົງ
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </ValidationObserver>
            </CCardBody>
          </CCard>
        </div>
      </CCol>
      <CCol sm="12">
        <CRow>
          <CCol sm="12">
            <h5 class="text-center">ສະແດງຂໍ້ມູນ</h5>
          </CCol>
          <CCol sm="1"></CCol>
          <CCol sm="3">ຊື່ ແລະ ນາມສະກຸນ :</CCol>
          <CCol sm="8">{{cus_full_name}}</CCol>
          <CCol sm="1"></CCol>
          <CCol sm="3">ເບີໂທ :</CCol>
          <CCol sm="8">{{cus_tel}}</CCol>
          <CCol sm="1"></CCol>
          <CCol sm="3">ທີ່ຢູ່ :</CCol>
          <CCol sm="8">{{cus_address}}</CCol>
          <CCol sm="1"></CCol>
          <CCol sm="3">ເລືອກບໍລິການ :</CCol>
          <CCol sm="8">{{service_name}}</CCol>
          <CCol sm="1"></CCol>
          <CCol sm="3">ລາຄາ :</CCol>
          <CCol sm="8">{{service_price | numFormat(',000.00')}} / {{service_unit}}</CCol>
          <CCol sm="1"></CCol>
          <CCol sm="3">ວັນສິນສຸດຂອງສັນຍາ :</CCol>
          <CCol sm="8">{{cont_end_date}}</CCol>
          <CCol sm="1"></CCol>
          <CCol sm="3">ລາຍລະອຽດ :</CCol>
          <CCol sm="8">{{con_sv_remark}}</CCol>
        </CRow>
      </CCol>
      <CCol sm="12">
        <div class="float-right">
          <CForm ref="form" :model="form" @submit.prevent="handleSubmit">
            <CButton type="submit" variant="outline" color="primary">
              <CIcon name="cil-save" />ບັນທຶກ
            </CButton>
            <CButton @click="handleCancle" variant="outline" color="warning">
              <CIcon name="cil-x" />ຍົກເລີກ
            </CButton>
          </CForm>
        </div>
      </CCol>
    </CRow>
  </div>
</template>
 <script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { getCustomerByIdAPI } from "@/api/customer";
import { getServiceByIdAPI } from "@/api/service";
import { decrypt } from "@/libs/crypto";
export default {
  name: "ContractFormCreate",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    cus: {
      type: Array,
      required: true,
    },
    service: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      form: {
        cus_id: "",
        service_id: "",
        con_sv_remark: "",
        cont_end_date: "",
      },
      cus_full_name: "",
      cus_address: "",
      cus_tel: "",
      cus_email: "",
      service_name: "",
      service_unit: "",
      service_price: "",
      con_sv_remark: "",
      cont_end_date: "",
      options: {
        format: "YYYY-MM-DD",
        useCurrent: false,
      },
    };
  },
  methods: {
    handleAddCus() {
      getCustomerByIdAPI(this.form.cus_id)
        .then((response) => {
          this.cus_full_name = response.data.data.cus_full_name;
          this.cus_address = response.data.data.cus_address;
          this.cus_tel = response.data.data.cus_tel;
          this.cus_email = response.data.data.cus_email;
          this.con_sv_remark = this.form.con_sv_remark;
          this.cont_end_date = this.form.cont_end_date;

          getServiceByIdAPI(this.form.service_id)
            .then((response) => {
              this.service_name = response.data.data.service_name;
              this.service_unit = response.data.data.service_unit;
              this.service_price = response.data.data.service_price;
            })
            .catch((error) => {
              this.$awn.alert("Respone Error, Please try again");
            });
        })
        .catch((error) => {
          this.$awn.alert("Respone Error, Please try again");
        });
    },
    handleCancle() {
      this.$emit("on_cancle", {});
      //clear
      this.form.cus_id = "";
      this.form.service_id = "";
      this.form.con_sv_remark = "";
      this.form.cont_end_date = "";
      this.cus_full_name = "";
      this.cus_address = "";
      this.cus_tel = "";
      this.cus_email = "";
      this.service_name = "";
      this.service_unit = "";
      this.service_price = "";
      this.con_sv_remark = "";
    },
    handleSubmit() {
      this.$emit("on_success", {
        cus_id: this.form.cus_id,
        service_id: this.form.service_id,
        con_sv_remark: this.form.con_sv_remark,
        service_price: this.service_price,
        cont_end_date: this.form.cont_end_date,
        cont_by: decrypt(this.$store.getters.getFullName),
      });
      //clear
      this.form.cus_id = "";
      this.form.service_id = "";
      this.form.con_sv_remark = "";
      this.form.cont_end_date = "";
      this.cus_full_name = "";
      this.cus_address = "";
      this.cus_tel = "";
      this.cus_email = "";
      this.service_name = "";
      this.service_unit = "";
      this.service_price = "";
      this.con_sv_remark = "";
    },
  },
};
</script>